/* index-mobile.css */
.container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure full viewport height */
    background-color: #1a1a1a; /* Dark background color */
    color: #ffffff; /* Light text color */
    padding: 20px; /* Added padding for better spacing */
    box-sizing: border-box;
}

h1 {
    align-items: center;
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.input-container {
    margin-bottom: 30px; /* Increased margin for better spacing */
}

.input-container input[type="text"] {
    width: calc(100% - 24px); /* Adjusted width to account for padding */
    padding: 12px; /* Increased padding for input */
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding in width calculation */
}

.button-container {
    text-align: center;
    margin-top: 20px; /* Added margin for better spacing */
}

.button-container button {
    padding: 12px 24px; /* Increased padding for button */
    font-size: 16px;
    background-color: #76ABAE; /* Blue button color */
    color: #ffffff; /* White button text color */
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.button-container button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}
