/* NotFoundPage.css */

.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #222831; /* Dark background color */
    color: #EEEEEE; /* Light text color */
}

.not-found-content {
    text-align: center;
}

.not-found-link {
    color: #EEEEEE; /* Light text color */
    text-decoration: underline;
}

.not-found-link:hover {
    color: #dddddd; /* Lighter text color on hover */
}
