/* Add custom styles to the Joystick component */
.mobile-control-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Style the joystick container */
.react-joystick-wrapper {
    background-color: rgba(34, 40, 49, 0.3); /* Background color with transparency */
    border-radius: 50%; /* Make the container round */
    width: 200px; /* Adjust the width of the joystick */
    height: 200px; /* Adjust the height of the joystick */
    display: flex;
    margin-bottom: 20px; /* Center the joystick horizontally */
    align-items: center;
    justify-content: center;
    padding: 20px; /* Add some padding */
}

/* Style the joystick base */
.react-joystick-base {
    background-color: rgba(49, 54, 63, 0.5); /* Base color with transparency */
    border-radius: 50%; /* Make the base round */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Style the joystick stick */
.react-joystick-stick {
    background-color: rgba(238, 238, 238, 0.7); /* Stick color with transparency */
    border-radius: 50%; /* Make the stick round */
    width: 50px; /* Adjust the width of the stick */
    height: 50px; /* Adjust the height of the stick */
}

.video-box {
    width: 100%; /* Ensure the video box occupies the full width */
    margin-top: 20px; /* Add some margin to separate from the joystick */
}
