/* desktopIndex.css */

body {
    font-family: Roboto, sans-serif;
    background-color: #222831; /* Dark background color */
    color: #EEEEEE; /* Light text color */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Ensure the body takes up at least the full height of the viewport */
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center;
    align-items: center; /* Center horizontally */
    background-color: #222831; /* Dark background color */
    color: #EEEEEE; /* Light text color */
    height: 100vh;
}

h1 {
    color: #EEEEEE; /* Light text color */
}

p {
    color: #EEEEEE; /* Light text color */
}

.input-container {
    display: flex;
    justify-content: center; /* Center button horizontally */
    align-items: center; /* Center button vertically */
    margin-bottom: 40px;
    padding: 20px;
    background: #31363F; /* Darker background color */
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #31363F; /* Darker border color */
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px; /* Add margin between input containers */
    background-color: #31363F; /* Darker background color */
    color: #EEEEEE; /* Light text color */
    text-align: Left
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #76ABAE; /* Lighter blue */
    color: #EEEEEE; /* Light text color */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center
}

button:hover {
    background-color: #49A69B; /* Darker shade of blue on hover */
    text-align: center
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}